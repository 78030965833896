
import { createSlice } from '@reduxjs/toolkit';
import { calendarApi } from '../__fakeApi__/calendarApi';
import {useKeycloak} from '@react-keycloak/web';
import axios from 'axios';
import {useAxios} from './utils';
import { API } from '../constants';

const initialState = {
  events: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(state, action) {
      state.events = action.payload;
    },
    createEvent(state, action) {
      state.events.push(action.payload);
    },
    selectEvent(state, action) {
      state.isModalOpen = true;
      state.selectedEventId = action.payload;
    },
    updateEvent(state, action) {
      const event = action.payload;

      state.events = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state, action) {
      state.events = state.events.filter((event) => event.id !== action.payload);
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

export const { reducer } = slice;

export const getEvents = () => async (dispatch) => {
  axios.get(API.URL+"/api/events/42372", {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
    }
  }).then((response) => {
    dispatch(slice.actions.getEvents(response.data));
  })
};

export const createEvent = (createData) => async (dispatch) => {
createData.site = '42372';
  axios.post(API.URL+'/api/events', createData, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
    }
  }).then((response) => {
    dispatch(slice.actions.createEvent(response.data));
  }, (error) => {
    console.log(error);
  });
};

export const selectEvent = (eventId) => async (dispatch) => {
  dispatch(slice.actions.selectEvent(eventId));
};

export const updateEvent = (eventId, update) => async (dispatch) => {
  update.site = '42372';
    axios.put(API.URL+'/api/events/'+eventId, update, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
      }
    }).then((response) => {
      dispatch(slice.actions.updateEvent(response.data));
    }, (error) => {
      console.log(error);
    });
};

export const deleteEvent = (eventId) => async (dispatch) => {
  // await calendarApi.deleteEvent(eventId);
  axios.delete(API.URL+'/api/events/'+eventId, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
    }
  }).then((response) => {
  dispatch(slice.actions.deleteEvent(eventId));
  });
};

export const selectRange = (start, end) => (dispatch) => {
  dispatch(slice.actions.selectRange({ start, end }));
};

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};



export default slice;
