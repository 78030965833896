export const THEMES = {
  LIGHT: 'LIGHT',
};

export const API = {
  URL: 'https://api.gstinc.link', // for live https://api.gstinc.link for local http://localhost:5000
      // URL: 'http://localhost:5000',
  GRAPHQL: 'https://api.gstinc.link/graphql' //for live https://api.gstinc.link/graphql for local http://localhost:5000
    // GRAPHQL: 'http://localhost:5000/graphql'
};

export const ERROR = {
  USER_NOT_AUTHORIZED: 'You are not authorized to access this section'
}
