// import { useRef, useState, setState, useEffect } from 'react';
import React, { useRef,memo, useCallback, useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import {
  Box,Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@material-ui/core';
import {GET_SITE_DETAILS} from '../../cache/clientGraphql';
import { cache, siteIdVar, siteIdOptionsVar } from '../../cache/cache';
import {useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import gtm from '../../lib/gtm';
import { useKeycloak } from '@react-keycloak/web';
import jwt_decode from "jwt-decode";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import { API } from '../../constants';

const SiteIdPopover = () => {
  const anchorRef = useRef(null);
  const [openSiteId, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const siteId = useReactiveVar(siteIdVar);
  const [li, setLi] = useState(null);
  const siteIdOptions = useReactiveVar(siteIdOptionsVar);
    const { keycloak, initialized } = useKeycloak()
  const decoded = jwt_decode(keycloak.token);
const [details, setDetails] = useState([]);
// console.log(decoded);
  const handleOpenSiteId = () => {
    setOpen(true);
  };

  const handleCloseSiteId = () => {
    setOpen(false);
  };

  const handleChangeSiteId = (siteId) => {
    console.log("From siteId Drop Down: "+ siteId);

    //const { data: currentData } = useQuery(GET_SITE_ID);
    cache.writeQuery({
      query: GET_SITE_DETAILS,
      data: {
        siteId: siteId      },
    });

    // To update siteId in the render using reactive variable
    siteIdVar(siteId);

    //console.log(cache);

    setOpen(false);
  };
  const getEmpDetails = useCallback(() => {
          axios.get(API.URL+"/api/mylocations", {
          headers: {
            'Authorization': `Bearer ${keycloak.token}`
          }
        }).then((response) => {
          setDetails(response.data);
          if(response.data.length > 0) {
            handleChangeSiteId(response.data[0].locationId);
            // console.log();
                // cache.writeQuery({
                //   query: GET_SITE_DETAILS,
                //   data: {
                //     siteId: response.data[0].locationId      },
                // });
          }
        });
      });
  useEffect(() => {
    getEmpDetails();
    gtm.push({ event: 'page_view' });
    // console.log("called siteid Popover")

  }, []);

  const { data, loading, error } = useQuery(GET_SITE_DETAILS);
  //const cartItems = useReactiveVar(siteIdVar);
  //console.log("data in siteIdPopOver: "+ data);


  return (
    <>
        <Button
          color="secondary"
          onClick={handleOpenSiteId}
          ref={anchorRef}
          variant="outline"
          endIcon={<ExpandMoreIcon />}
        >
          <Box
            sx={{
              display: 'flex',
              color: 'secondary'

            }}
          >
            {details.map((item) => {
              if (item.locationId == siteId) {
                return (item.locationId + " - " + item.name)
              }
            }
            )}
          </Box>
        </Button>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleCloseSiteId}
        open={openSiteId}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        {details.map((item) => (
          <MenuItem
            onClick={() => handleChangeSiteId(item.locationId)}
            key={item.locationId}
          >
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {item.locationId + " - " + item.name}
                </Typography>
              )}
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default SiteIdPopover;
