export const zeroPad = (value, length) => {
    return `${value}`.padStart(length, '0');
  }
  
  export const getDateISO = (date = new Date(), days = 0) => {
  
    return [
      date.getFullYear(),
      zeroPad(+date.getMonth() + 1, 2),
      zeroPad(+date.getDate() - (days != undefined ? days : 0), 2)
    ].join('-'); 
  }

  export const firtDayOfMonth = (date = new Date()) => {
    const y = date.getFullYear(), m = date.getMonth();
    var date1 = new Date(y, m, 1);
    return [
      date1.getFullYear(),
      zeroPad(+date1.getMonth() + 1, 2),
      zeroPad(+date1.getDate(), 2)
    ].join('-'); 
  }