import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { ApolloClient, InMemoryCache , HttpLink} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ApolloProvider } from "@apollo/react-hooks";
import keycloak from './keycloak';
import {createUploadLink} from 'apollo-upload-client';
import store from './store';
import { cache } from './cache/cache';
import { API } from './constants';

const Loading = () => <div>Loading...</div>
const eventLogger = (event: unknown, error: unknown) => {
  // console.log('onKeycloakEvent', event, error)
}

const tokenLogger = (tokens: unknown) => {
  localStorage.setItem('auth_token', tokens.token);
}

  const httpLink = createUploadLink({uri: API.GRAPHQL});
  const setAuthorizationLink = setContext((request, previousContext) => ({
    headers: {
      ...previousContext.headers,
      authorization: `Bearer ${ localStorage.getItem('auth_token') }`
    }
  }));
  const client = new ApolloClient({
    link: setAuthorizationLink.concat(httpLink),
    cache: cache,
    connectToDevTools: true
  });

ReactDOM.render(
  <StrictMode>
  <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{
            onLoad: "login-required",
        }}
        LoadingComponent={<Loading />}
        onEvent={eventLogger}
        onTokens={tokenLogger}
    >
        <HelmetProvider>
        <ReduxProvider store={store}>
            <StyledEngineProvider injectFirst>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider>
                  <BrowserRouter>
                  <ApolloProvider client={client}>
                        <App />
                    </ApolloProvider>
                  </BrowserRouter>
                </SettingsProvider>
              </LocalizationProvider>
            </StyledEngineProvider>

      </ReduxProvider>
        </HelmetProvider>
    </ReactKeycloakProvider>
  </StrictMode>, document.getElementById('root')
);
