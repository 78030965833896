import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Divider, Drawer, Link, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReceiptIcon from '@material-ui/icons/Receipt';
import useAuth from '../../hooks/useAuth';
import BriefcaseIcon from '../../icons/Briefcase';
import CalendarIcon from '../../icons/Calendar';
import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ChatAltIcon from '../../icons/ChatAlt';
import ClipboardListIcon from '../../icons/ClipboardList';
import FolderOpenIcon from '../../icons/FolderOpen';
import MailIcon from '../../icons/Mail';
import ShareIcon from '../../icons/Share';
import ShoppingBagIcon from '../../icons/ShoppingBag';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import StoreIcon from '@material-ui/icons/Store';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import TodayIcon from '@material-ui/icons/Today';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import InventoryIcon from '@material-ui/icons/Inventory';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ReceiptLongIcon from '@material-ui/icons/ReceiptLong';
import UpdateIcon from '@material-ui/icons/Update';
import AdminPanelSettingsIcon from '@material-ui/icons/AdminPanelSettings';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArticleIcon from '@material-ui/icons/Article';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TransformIcon from '@material-ui/icons/Transform';
import SyncIcon from '@material-ui/icons/Sync';
import OpacityIcon from '@material-ui/icons/Opacity';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

const sections = [
  {
    title: 'General',
    items: [
      {
        title: 'Daily Summary',
        path: '/',
        icon: <TodayIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Reports',
    items: [
        {
          title: 'Daily Sales',
          path: '/dailyhistory',
          icon: <EqualizerIcon fontSize="small" />
        },    {
            title: 'Reconcile',
            path: '/inquiries',
            icon: <SyncIcon fontSize="small" />,
            children: [{
              title: 'Fuel',
              path: '/fuelrecon',
              icon: <LocalGasStationIcon fontSize="small" />
            },{
              title: 'Accounts',
              path: '/accountsrecon',
              icon: <AccountBalanceIcon fontSize="small" />
            },{
              title: 'Safedrops',
              path: '/safedropedits',
              icon: <SafetyCheckIcon fontSize="small" />
            },
            ]
          },
      {
        title: 'EFT',
        path: '/eft',
        icon: <TransformIcon fontSize="small" />
      },{
        title: 'Credit Card Payment',
        path: '/creditcardpayment',
        icon: <CreditCardIcon fontSize="small" />
      },
      {
      title: 'Fuel',
      path: '/inquiries',
      icon: <LocalGasStationIcon fontSize="small" />,
      children: [{
        title: 'Fuel Delivery',
        path: '/fueldelivery',
        icon: <LocalShippingIcon fontSize="small" />
      },{
        title: 'Fuel Price',
        path: '/fuelpricechange',
        icon: <AttachMoneyIcon fontSize="small" />
      },
      ]
    },
          // {
          //     title: 'Transactions',
          //     path: '/transactions',
          //     icon: <ReceiptIcon fontSize="small" />
          //   },
            {
              title: 'Departments',
              path: '/departments',
              icon: <StoreIcon fontSize="small" />
            },
    ]
  },
  {
    title: 'Veeder-Root',
    items: [
      // {
      //   title: 'Dashboard',
      //   path: '/',
      //   icon: <ChartSquareBarIcon fontSize="small" />
      // },
      {
        title: 'TLS350',
        path: '/tls350',
        icon: <DeveloperBoardIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Management',
    items: [
{
title: 'Settings',
path: '/inquiries',
icon: <AdminPanelSettingsIcon fontSize="small" />,
children: [
  {
  title: 'Location',
  path: '/locations',
  icon: <LocationOnIcon fontSize="small" />
  },
  {
  title: 'Bank Accounts',
  path: '/bankaccounts',
  icon: <AccountBalanceIcon fontSize="small" />
  },
// {
// title: 'User',
// path: '/users',
// icon: <UsersIcon fontSize="small" />
// },
  {
  title: 'Notifications',
  path: '/notifications',
  icon: <SettingsSuggestIcon fontSize="small" />
  }
]
},
// {
// title: 'Inventory',
// path: '/inquiries',
// icon: <InventoryIcon fontSize="small" />,
// children: [
//   {
//   title: 'SiteFiles',
//   path: '/sitefiles',
//   icon: <ArticleIcon fontSize="small" />
//   },  {
//     title: 'Catalog',
//     path: '/catalogs',
//     icon: <MenuBookIcon fontSize="small" />
//     },{
// title: 'Invoice',
// path: '/invoices',
// icon: <ReceiptLongIcon fontSize="small" />
// },{
// title: 'Forecast',
// path: '/dssr',
// icon: <UpdateIcon fontSize="small" />
// },
// ]
// }
    ]
  },
  {
    title: 'Tools',
    items: [
      {
        title: 'Calendar',
        path: '/calendar',
        icon: <CalendarIcon fontSize="small" />
      }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 120,
                width: 220
              }}
            />
          </RouterLink>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Need Help?
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Call us at 213-213-2132
          </Typography>
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
