import { InMemoryCache, makeVar, gql, useQuery, useLazyQuery } from "@apollo/client";
import {GET_SITE_DETAILS} from './clientGraphql';
import {getDateISO, firtDayOfMonth} from '../utils/dateConversion';

export const cache = new InMemoryCache({
  /*typePolicies: {
    Query: {
      fields: {
        dailyDeptSales: {
          read () {
            return siteIdVar();
          }
        },
        dailyFuelSales: {
          read () {
            return siteIdVar();
          }
        }
      }
    }
  }*/
});

// Create the initial value
const sitesInitialValue = {
    siteId: '42287' //42287, 42409
};

const siteIdOptions = [
{siteName: '42309 - 24th Street', siteId: '42309'},
{siteName: '42523 - 32nd Street', siteId: '42523'},
{siteName: '42075 - Baker', siteId: '42075'},
{siteName: '66489 - Blythe', siteId: '66489'},
{siteName: '42333 - Broadway', siteId: '42333'},
{siteName: '42408 - Bullhead', siteId: '42408'},
{siteName: '42372 - Carson', siteId: '42372'},
{siteName: '42407 - Flamingo', siteId: '42407'},
{siteName: '42493 - GB Nevada', siteId: '42493'},
{siteName: '42287 - Irwindale', siteId: '42287'},
{siteName: '42410 - Kingman', siteId: '42410'},
{siteName: '42409 - Lake Havasu', siteId: '42409'},
{siteName: '42794 - Placentia', siteId: '42794'},
{siteName: '42502 - Quartz', siteId: '42502'},
{siteName: '82934 - San Jose', siteId: '82934'},
{siteName: 'AB123 - Rancho Shell', siteId: 'AB123'},
{siteName: '42370 - Tropicana', siteId: '42370'}];


// Create the sites var and initialize it with the initial value, this is reactive variable
export const siteIdVar = makeVar('42372');

// For all site options
export const siteIdOptionsVar = makeVar(siteIdOptions);

// For report date
export const reportDateVar = makeVar(''+getDateISO(new Date(), 1));

// For start and end date
export const startDateVar = makeVar(''+firtDayOfMonth(new Date()));
export const endDateVar = makeVar(''+getDateISO(new Date(), 1));

/**
 * Setting the initial cache with default siteId
 * this will update later when user changes the siteId from the siteId drop down
 */
cache.writeQuery({
  query: GET_SITE_DETAILS,
  data: {
    siteId: siteIdVar(),
    reportDate: reportDateVar(),
    startDate: startDateVar(),
    endDate: endDateVar(),
    siteIdOptions: siteIdOptions
  }
});
