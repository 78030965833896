import { Suspense, lazy } from 'react';
import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);


const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
const CompaniesList = Loadable(lazy(() => import('./pages/dashboard/CompaniesList')));
const TransactionsList = Loadable(lazy(() => import('./pages/dashboard/TransactionsList')));
const CompanyCreate = Loadable(lazy(() => import('./pages/dashboard/CompanyCreate')));
const CompanyEdit = Loadable(lazy(() => import('./pages/dashboard/CompanyEdit')));
const CompanyDetails = Loadable(lazy(() => import('./pages/dashboard/CompanyDetails')));
const EmployeeDetails = Loadable(lazy(() => import('./pages/dashboard/EmployeeDetails')));

const DssrList = Loadable(lazy(() => import('./pages/dashboard/DssrList')));
const DSSRCustom = Loadable(lazy(() => import('./pages/dashboard/DSSRCustom')));
const FuelInventoryList = Loadable(lazy(() => import('./pages/dashboard/FuelInventoryList')));
const DeptList = Loadable(lazy(() => import('./pages/dashboard/DeptList')));
const DepartmentList = Loadable(lazy(() => import('./pages/dashboard/DepartmentList')));

const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));

const SiteFiles = Loadable(lazy(() => import('./pages/dashboard/SiteFilesList')));
const SiteFilesCreate = Loadable(lazy(() => import('./pages/dashboard/SiteFilesCreate')));

const Locations = Loadable(lazy(() => import('./pages/dashboard/LocationsList')));
const LocationCreate = Loadable(lazy(() => import('./pages/dashboard/LocationCreate')));
const Users = Loadable(lazy(() => import('./pages/dashboard/UsersList')));
const UserCreate = Loadable(lazy(() => import('./pages/dashboard/UserCreate')));
const Catalogs = Loadable(lazy(() => import('./pages/dashboard/CatalogList')));
const Invoices = Loadable(lazy(() => import('./pages/dashboard/InvoicesList')));
const BankAccounts = Loadable(lazy(() => import('./pages/dashboard/BankAccountsList')));


const DailyReport = Loadable(lazy(() => import('./pages/DailyReport')));
const DailyHistory = Loadable(lazy(() => import('./pages/DailyHistory')));
const DailyCashier = Loadable(lazy(() => import('./pages/DailyCashier')));
const DeptSales = Loadable(lazy(() => import('./pages/DeptSales')));
const HourlySales = Loadable(lazy(() => import('./pages/HourlySales')));
const TankInventory = Loadable(lazy(() => import('./pages/TankInventory')));
const DailyTrans = Loadable(lazy(() => import('./pages/DailyTrans')));
const FuelDelivery = Loadable(lazy(() => import('./pages/FuelDelivery')));
const FuelRecon = Loadable(lazy(() => import('./pages/FuelRecon')));
const DollarsRecon = Loadable(lazy(() => import('./pages/DollarsRecon')));
const SafeDropEdits = Loadable(lazy(() => import('./pages/SafeDropEdits')));
const TLS350 = Loadable(lazy(() => import('./pages/TLS350')));
const EFTDrafts = Loadable(lazy(() => import('./pages/EFTDrafts')));
const FuelPriceChange = Loadable(lazy(() => import('./pages/FuelPriceChange')));
const CreditCardPayment = Loadable(lazy(() => import('./pages/CreditCardPayment')));

const Manager = Loadable(lazy(() => import('./pages/Manager')));
const ACH = Loadable(lazy(() => import('./pages/ach/ACH')));
const NotificationsList = Loadable(lazy(() => import('./pages/dashboard/NotificationsList')))


const routes = [

    {
      path: '/',
      element: <DashboardLayout />,
      children: [
          {
            path: '/dailyreport/:site/:date/:reportId',
            element: <DailyReport />
          },
            {
              path: '/dailyhistory',
              element: <DailyHistory />
            },
              {
                path: '/fueldelivery',
                element: <FuelDelivery />
              },
                {
                  path: '/fuelrecon',
                  element: <FuelRecon />
                },
                  {
                    path: '/accountsrecon',
                    element: <DollarsRecon />
                  },
                    {
                      path: '/safedropedits',
                      element: <SafeDropEdits />
                    },
                  {
                    path: '/tls350',
                    element: <TLS350 />
                  },
                    {
                      path: '/eft',
                      element: <EFTDrafts />
                    },
                      {
                        path: '/fuelpricechange',
                        element: <FuelPriceChange />
                      },
                        {
                          path: '/creditcardpayment',
                          element: <CreditCardPayment />
                        },
                          {
                            path: '/manager',
                            element: <Manager />
                          },
                            {
                              path: '/overview',
                              element: <Overview />
                            },
                              {
                                path: '/transactions',
                                element: <TransactionsList />
                              },
                                {
                                  path: '/departments',
                                  element: <DepartmentList />
                                },
                                  {
                                    path: '/bankaccounts',
                                    element: <BankAccounts />
                                  },
              {
                path: '/dailycashier/:site/:date/:cashier/:reportId',
                element: <DailyCashier />
              },
                {
                  path: '/deptsales/:site/:date/:reportId',
                  element: <DeptSales />
                },
                  {
                    path: '/hourlysales/:site/:date/:reportId',
                    element: <HourlySales />
                  },
                    {
                      path: '/tankinventory/:site/:date/:reportId',
                      element: <TankInventory />
                    },
                      {
                        path: '/trans/:site/:date/:reportId',
                        element: <DailyTrans />
                      },
                      {
                        path: 'calendar',
                        element: <Calendar />
                      },      {
                              path: '',
                              element: <Analytics />
                            },
                            {
                              path: 'ach',
                              element: <ACH />
                            },
                            {
                              path: 'locations',
                              children: [
                                {
                                  path: '/locations',
                                  element: <Locations />
                                },
                                {
                                  path: '/locations/:id',
                                  element: <LocationCreate />
                                }
                              ]
                            },
                            {
                              path: 'users',
                              children: [
                                {
                                  path: '/users',
                                  element: <Users />
                                },
                                {
                                  path: 'new',
                                  element: <UserCreate />
                                },
                                {
                                  path: '/users/:id',
                                  element: <UserCreate />
                                }
                              ]
                            },
                            ,
                            {
                              path: 'notifications',
                              children: [
                                {
                                  path: '/notifications',
                                  element: <NotificationsList />
                                }
                              ]
                            },
                    {
                      path: '401',
                      element: <AuthorizationRequired />
                    },
                    {
                      path: '404',
                      element: <NotFound />
                    },
                    {
                      path: '500',
                      element: <ServerError />
                    },
                    {
                      path: '*',
                      element: <NotFound />
                    },
  {
    path: '/',
    element: (
        <DashboardLayout />
    ),
    children: [
      // {
      //   path: 'analytics',
      //   element: <Analytics />
      // },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'analytics',
        element: <Analytics />
      },
      {
        path: 'calendar1',
        element: <Calendar />
      },      {
        path: 'transactions',
        children: [
          {
            path: ':companyId/edit',
            element: <CompanyEdit />
          },
          {
            path: ':companyId',
            element: <CompanyDetails />
          },
          {
            path: 'new',
            element: <CompanyCreate />
          },
          {
            path: ':companyId/employees/:employeeId',
            element: <EmployeeDetails />
          }
        ]
      },
      {
        path: 'dssr',
        children: [
          {
            path: '/dssr',
            element: <DssrList />
          },
          {
            path: ':reportId/custom',
            element: <DSSRCustom />
          }
        ]
      },

      {
        path: 'fuelinventory',
        children: [
          {
            path: '/fuelinventory',
            element: <FuelInventoryList />
          }
        ]
      },
      {
        path: 'dept',
        children: [
          {
            path: '/dept',
            element: <DeptList />
          }
        ]
      },
      {
        path: 'departments',
        children: [
        ]
      },
      {
        path: 'sitefiles',
        children: [
          {
            path: '/sitefiles',
            element: <SiteFiles />
          },
          {
            path: 'new',
            element: <SiteFilesCreate />
          }
        ]
      },
      {
        path: 'catalogs',
        children: [
          {
            path: '/catalogs',
            element: <Catalogs />
          }
        ]
      },
      {
        path: 'invoices',
        children: [
          {
            path: '/invoices',
            element: <Invoices />
          }
        ]
      },
    ]
  }


    ]
  }
];

export default routes;
